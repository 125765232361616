import { gql } from '@apollo/client';

export const OFFERS_BY_BRAND = gql`
  query OffersByBrand($options: GetOffersAsManagerInput!) {
    offersByBrand(options: $options) {
      rows {
        id
        name
        description
        amountPersons
        budgetPerPerson
        status
        isFavorite
        brand {
          id
          title
          description
          isActive
          restaurantsCount
          image {
            url
          }
        }
        image {
          id
          width
          height
          mime
          size
          url
        }
        scheduleType
        workDays {
          id
          day
          from
          to
          isActive
        }
        restaurants {
          id
          name
          scheduleType
          priceLevel
          description
          averageCost
          address
          latitude
          longitude
          availableSeatsNumber
          isClosed
          brandId
          createdAt
          updatedAt
          closedFrom
          closedTo
          city {
            id
            title
            latitude
            longitude
          }
          image {
            url
          }
        }
        cuisines {
          id
          title
        }
        eventThemes {
          id
          title
          isFavorite
        }
        offerDishes {
          quantity
          dishId
          offerId
          dish {
            id
            title
            description
            brandId
            dishCategoryId
            price
            cookingTime
            amountPersons
            calories
            weight
            volume
            image {
              url
              id
            }
          }
        }
        createdAt
        updatedAt
        distance
      }
      count
    }
  }
`;
