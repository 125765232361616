import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  split,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import create from 'zustand';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { print } from 'graphql';
import { createClient } from 'graphql-ws';
import {
  paginationMergeGetApplications,
  paginationMergeGetBrands,
  paginationMergeGetDishes,
} from './pagination-merge-functions';
import { LS } from '../constants/constants';
import { paginationMergeGetOffersBySuperAdmin } from './pagination-merge-functions/paginationMergeGetOffersBySuperAdmin';

export class WebSocketLink extends ApolloLink {
  constructor(options) {
    super();
    this.client = createClient(options);
  }

  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err);
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`,
                ),
              );
            }

            return sink.error(
              new Error(err.map(({ message }) => message).join(', ')),
            );
          },
        },
      );
    });
  }
}

const wsLink = new WebSocketLink({
  // eslint-disable-next-line no-undef
  url: `${process.env.REACT_APP_API_HOST_WSS}/subscriptions`,
  connectionParams: () => {
    const token =
      localStorage.getItem(LS.TOKEN_BYDE_ADMIN) ||
      sessionStorage.getItem(LS.TOKEN_BYDE_ADMIN);

    return {
      Authorization: `Bearer ${token}`,
    };
  },
});

export const useErrorsStore = create(() => ({
  hasError: false,
  error: null,
  date: null,
}));

const httpLink = createUploadLink({
  // eslint-disable-next-line no-undef
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError || graphQLErrors[0]) {
    useErrorsStore.setState({
      hasError: true,
      error: networkError || graphQLErrors[0],
      date: Date.now(),
    });
  }
  if (networkError && networkError.statusCode === 401) {
    localStorage.removeItem('token');
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token =
    localStorage.getItem(LS.TOKEN_BYDE_ADMIN) ||
    sessionStorage.getItem(LS.TOKEN_BYDE_ADMIN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache({
    addTypename: true,
    typePolicies: {
      Query: {
        fields: {
          ...paginationMergeGetBrands([]),
          ...paginationMergeGetDishes([]),
          ...paginationMergeGetApplications([]),
          ...paginationMergeGetOffersBySuperAdmin([]),
        },
      },
    },
  }),
  connectToDevTools: true,
});
