import { Box, Button, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';

export const FieldArrayRow = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const FieldArrayRowInner = styled(Box)({
  display: 'flex',
  alignItems: 'end',
  gap: '16px',
});

export const ButtonSaveChangesCSS = styled(Button)(() => ({
  width: 'fit-content',
  background: palette.green400,
  '&:hover': {
    background: palette.green400,
  },
}));
