import { IFormikProps, IProps } from './types';
import { restaurantSchema } from '../../validation';
import { Form, Formik } from 'formik';
import {
  DatePickerRowCSS,
  DropzoneBlockCSS,
  MainBlockCSS,
  SwitcherBlockCSS,
  TitleBlockCSS,
  Wrapper,
} from './styles';
import { DropzoneFormik } from '../UI/DropzoneFormik/DropzoneFormik';
import { SwitcherFormik } from '../UI/SwitcherFormik/SwitcherFormik';
import { DatePickerFormik } from '../UI/DatePickerFormik/DatePickerFormik';
import { Button, Typography } from '@mui/material';
import { useUpdateRestaurantMutation } from '../../graphql/generated/graphql';
import { RESTAURANT } from '../../graphql/queries/restaurant';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useAlertContext } from '../../context';
import { useAccesses } from '../../hooks';

export const RestaurantCardInfoForm = ({ data }: IProps) => {
  const { onOpenAlert } = useAlertContext();
  const { t } = useTranslatesContext();
  const { isAddRestaurantAccess } = useAccesses();

  const [updateRestaurant] = useUpdateRestaurantMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_update_restaurant') });
    },
    refetchQueries: [RESTAURANT],
  });

  const handleSubmit = (values: IFormikProps) => {
    const formValues = {
      id: Number(data.id),
      image: typeof values.logo === 'string' ? null : values.logo,
      name: data.name,
      workDays: data.workDays.map((item) => {
        const newItem = { ...item };
        delete newItem.__typename;
        return newItem;
      }),
      isClosed: values.switchWorking,
      closedFrom: values.switchWorking ? values.dataIn : null,
      closedTo: values.switchWorking ? values.dataOut : null,
      address: data.address,
      availableSeatsNumber: data.availableSeatsNumber,
      averageCost: data.averageCost,
      brandId: data.brandId,
      cityId: data.city.id,
      description: data.description,
      cuisinesIds: data.cuisines.map((i) => i.id),
      latitude: data.latitude,
      longitude: data.longitude,
      priceLevel: data.priceLevel,
      scheduleType: data.scheduleType,
    };

    updateRestaurant({
      variables: {
        updateRestaurantInput: {
          ...formValues,
        },
      },
    });
  };
  const today = new Date();
  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

  const init = {
    logo: data?.image?.url || null,
    switchWorking: data?.isClosed,
    dataIn: data?.closedFrom
      ? new Date(+data?.closedFrom).toISOString()
      : new Date().toISOString(),
    dataOut: data?.closedTo
      ? new Date(+data?.closedTo).toISOString()
      : tomorrow.toISOString(),
  };

  return (
    <Formik<IFormikProps>
      onSubmit={handleSubmit}
      initialValues={init}
      validationSchema={restaurantSchema}
    >
      {({ values }) => {
        return (
          <Form>
            <Wrapper>
              <DropzoneBlockCSS>
                <DropzoneFormik name={'logo'} isVertical isHelperText />
              </DropzoneBlockCSS>
              <MainBlockCSS>
                <SwitcherBlockCSS>
                  <SwitcherFormik
                    name={'switchWorking'}
                    label={t('web_restaurant_header_temporarily_close')}
                    disabled={!isAddRestaurantAccess}
                  />
                </SwitcherBlockCSS>
                {values.switchWorking ? (
                  <DatePickerRowCSS>
                    <DatePickerFormik
                      name={'dataIn'}
                      label={t('web_restaurant_header_date_from_label')}
                    />
                    <DatePickerFormik
                      name={'dataOut'}
                      minDate={values.dataIn}
                      label={t('web_restaurant_header_date_to_label')}
                    />
                  </DatePickerRowCSS>
                ) : null}
                <TitleBlockCSS>
                  <Typography variant={'big700'}>{data.name}</Typography>
                  <Typography variant={'sm550'}>{data.description}</Typography>
                </TitleBlockCSS>
                <Button
                  type={'submit'}
                  sx={{ width: 'fit-content' }}
                  disabled={!isAddRestaurantAccess}
                >
                  {t('web_action_edit')}
                </Button>
              </MainBlockCSS>
            </Wrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
