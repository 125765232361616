import { useState } from 'react';
import { DEFAULT_PAGINATION_OPTIONS } from '../constants/constants';

type PaginationStateType = {
  limit: number;
  offset: number;
  itemsCount: number;
  page: number;
  search?: string;
};

export const usePagination = () => {
  const [paginationOptions, setPaginationOptions] =
    useState<PaginationStateType>(DEFAULT_PAGINATION_OPTIONS);

  const handleLoadMore = (
    fetchMore: (params: Record<string, any>) => void,
    prevData?: Record<string, any>,
  ) => {
    const options = {
      ...(prevData && prevData),
      limit: paginationOptions.limit,
      offset: paginationOptions.offset + 10,
    };

    fetchMore({
      variables: {
        options,
      },
    });
    setPaginationOptions((prev) => ({
      ...prev,
      ...options,
      itemsCount: prev.itemsCount + prev.limit,
    }));
  };

  const handleChangePage = (page: number) => {
    setPaginationOptions((prev) => {
      return {
        ...prev,
        offset: prev.itemsCount * (page - 1),
        page,
      };
    });
  };

  return {
    paginationOptions,
    handleLoadMore,
    handleChangePage,
    setPaginationOptions,
  };
};
