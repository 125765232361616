import { Box, styled } from '@mui/material';

export const ApplicationItemRowCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  marginRight: '56px',
  width: '100%',
  maxWidth: '200px',
});
