import { createTheme } from '@mui/material';

// eslint-disable-next-line import/namespace
import * as foundations from './foundations/index';
import { palette } from './foundations/index';
import { CSSProperties } from 'react';

export const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'medium',
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            input: {
              fontWeight: 400,
              fontSize: '13px',
              lineHeight: '18px',
              color: palette.input.text,
              padding: 0,
            },
            '.MuiInputBase-root': {
              paddingInlineStart: '14px',
              paddingInlineEnd: '4px',
              minHeight: '38px',
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            input: {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '20px',
              color: palette.input.text,
              padding: 0,
            },
            '.MuiInputBase-root': {
              paddingInlineStart: '14px',
              paddingInlineEnd: '4px',
              minHeight: '44px',
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            input: {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '20px',
              color: palette.input.text,
              padding: 0,
            },
            '.MuiInputBase-root': {
              paddingInlineStart: '14px',
              paddingInlineEnd: '4px',
              minHeight: '50px',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '& [placeholder]::placeholder': {
            color: palette.input.placeholder,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
            opacity: 1,
          },
          '.MuiInputBase-root': {
            borderRadius: '6px',
            '.MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.input.border}`,
            },
            ':hover .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.input.outline}`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.input.outline}`,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.error.main}`,
            },
            '& .MuiInputAdornment-root': {
              marginRight: 0,
            },
          },

          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
            {
              WebkitAppearance: 'none',
              margin: '0',
            },
          input: {
            MozAppearance: 'textfield', // FOR MOZILA
          },
          background: '#fff',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'medium',
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          width: '100%',
          height: '44px',
          borderRadius: '12px',
          fontSize: 16,
          fontWeight: 500,
          padding: '12px 16px',
          textTransform: 'none',
          textAlign: 'center',
          color: palette.white,
        },
      },
      variants: [
        {
          props: {
            variant: 'contained',
          },
          style: {
            backgroundColor: palette.primary.main,
            '&:hover': {
              background: palette.primary.main,
            },
            '&.Mui-disabled': {
              color: '#fff',
            },
          },
        },
        {
          props: {
            variant: 'outlined',
          },
          style: {
            backgroundColor: palette.white,
            border: `1px solid ${palette.primary.main}`,
            color: palette.brown500,
            '&:hover': {
              background: palette.white,
            },
          },
        },
        {
          props: {
            variant: 'text',
          },
          style: {
            background: palette.button.textBg,
            border: 'none',
            color: palette.brown500,
            fontSize: '12px',
            lineHeight: '14.06px',
            borderRadius: 0,
            '&:hover': {
              background: palette.button.textBg,
            },
          },
        },
        {
          props: {
            size: 'large',
          },
          style: {
            height: '50px',
            fontSize: '16px',
            letterSpacing: '0.005em',
          },
        },
        {
          props: {
            variant: 'tab',
          },
          style: {
            background: palette.white200,
            color: palette.brown500,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
            padding: '6px 12px',
            height: 'auto',
            borderRadius: '8px',
            minWidth: 'auto',
            ':hover': {
              background: palette.white300,
            },
          },
        },
        {
          props: {
            variant: 'choose',
          },
          style: {
            background: palette.white200,
            color: palette.brown500,
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '0.005em',
            padding: '6px 14px',
            height: 'auto',
            borderRadius: '10px',
            width: 'fit-content',
            minWidth: 'auto',
            outline: `1px solid ${palette.primary.main}`,
            ':hover': {
              background: palette.white300,
            },
          },
        },
        {
          props: {
            variant: 'visible',
          },
          style: {
            background: palette.white200,
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '0.005em',
            padding: '6px 14px',
            height: 'auto',
            borderRadius: '10px',
            width: 'fit-content',
            minWidth: 'auto',
            color: palette.primary.main,
            backgroundColor: palette.white,
          },
        },
      ],
    },
    MuiAvatar: {
      variants: [
        {
          props: {},
          style: {
            width: '36px',
            height: '36px',
            img: {
              objectFit: 'fill',
            },
          },
        },
      ],
    },
    MuiBadge: {
      variants: [
        {
          props: {},
          style: {
            marginLeft: 'auto',
            marginRight: '14px',
            '.MuiBadge-badge': {
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              fontWeight: 700,
              fontSize: '14px',
            },
          },
        },
      ],
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        size: 'medium',
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            height: '50px',
            borderRadius: '8px',
            color: palette.input.text,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '20px',
            border: 'none',

            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.white400} !important`,
            },

            '.MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
              padding: '13.5px 14px',
            },
            '.MuiInputBase-root:hover': {
              border: 'none !important',
            },
            '.MuiSelect-icon': {
              right: '14px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.error.main} !important`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.input.outline} !important`,
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            height: '44px',
            borderRadius: '8px',

            color: palette.input.text,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '20px',
            border: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid rgba(150, 160, 181, 0.4) !important',
            },

            '.MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
              padding: '13.5px 14px',
            },
            '.MuiInputBase-root:hover': {
              border: 'none !important',
            },
            '.MuiSelect-icon': {
              right: '14px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.error.main} !important`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${palette.input.outline} !important`,
            },
          },
        },
      ],
      styleOverrides: {
        select: {
          background: '#fff',
        },
      },
    },
    MuiAutocomplete: {
      variants: [
        {
          props: {},
          style: {
            '.MuiInputBase-root': {
              minHeight: '50px',
            },
            '.MuiOutlinedInput-root': {
              padding: '6px',
            },
            '.MuiChip-root': {
              borderRadius: '8px',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '20px',
            },
            '.MuiAutocomplete-endAdornment': {
              right: '14px !important',
            },
          },
        },
      ],
    },
    MuiPaginationItem: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            width: '44px',
            height: '44px',
            fontWeight: 400,
            fontSize: '16px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      ],
    },
    MuiDialog: {
      variants: [
        {
          props: {},
          style: {
            ':root': {},
          },
        },
      ],
    },
    MuiPaper: {
      variants: [
        {
          props: {
            variant: 'elevation',
          },
          style: {
            borderRadius: '16px',
            boxShadow: '0px 2px 16px rgba(33, 52, 103, 0.1)',
            // padding: '16px',
          },
        },
      ],
    },
    MuiTableHead: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      variants: [
        {
          props: {
            size: 'medium',
          },
          style: {
            '&:nth-of-type(1)': {
              minWidth: '150px',
            },
            padding: '14px 16px',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            color: '#B5B1AE',
          },
        },
        {
          props: {
            size: 'small',
          },
          style: {
            padding: '8px 0px 8px 16px',
            fontWeight: '400',
            fontSize: '13px',
            lineHeight: '18px',
            color: '#3D3835',
            border: 'none',
            '&:last-of-type': {
              paddingLeft: '8px',
              paddingRight: '8px',
            },
            '&:nth-of-type(1)': {
              maxWidth: '350px',
              overflow: 'auto',
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        '&::-webkit-scrollbar': {
          borderRadius: '2px',
          width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#FD720D',
          borderRadius: '10px',
          border: '5px solid #fff',
        },
      },
    },
  },
  ...foundations,
  mixins: {
    hoverItem: {
      transition: '0.3s',
      ':hover': {
        boxShadow: '0px 2px 16px rgba(33, 52, 103, 0.6)',
        transition: '0.3s',
      },
    },
  },
});

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tab: true;
    choose: true;
    visible: true;
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    hoverItem?: CSSProperties | Record<string, CSSProperties>;
  }
}
