import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ROUTES } from '../constants/constants';
import {
  Home,
  NotFound,
  Login,
  DevPage,
  Registration,
  OrdersPage,
  MenuPage,
  ApplicationsPage,
  BrandsPage,
  SettingsPage,
  TranslationsPage,
  AddOrUpdateBrand,
  ResetPassword,
  RegistrationOffer,
  Brand,
  RestaurantsPage,
  AddOrUpdateRestaurant,
  AddOrUpdateUser,
  AddOrUpdateDish,
  Restaurant,
  OrderPage,
  Application,
  OffersSearchedNowInfoPage,
  AdminOffersPage,
  AdminOfferEditDishesPage,
  AddOrUpdateAdminOffersPage,
  PrivacyPolicy,
  AgreementPage,
  OffersSearchedNowPage,
} from '../pages';
import { useAuthContext } from '../context';
import { UsersPage } from '../pages/UsersPage/UsersPage';

type RouteType = {
  path: string;
  element: () => JSX.Element;
  isAuthRoute?: boolean;
  isSuperAdminRoute?: boolean;
};

const routes: Array<RouteType> = [
  {
    path: ROUTES.home,
    element: Home,
  },
  {
    path: ROUTES.login,
    element: Login,
    isAuthRoute: true,
  },
  {
    path: ROUTES.registration,
    element: Registration,
    isAuthRoute: true,
  },
  {
    path: ROUTES.resetPassword,
    element: ResetPassword,
    isAuthRoute: true,
  },
  {
    path: ROUTES.registrationOffer,
    element: RegistrationOffer,
    isAuthRoute: true,
  },

  {
    path: ROUTES.devPage,
    element: DevPage,
  },
  {
    path: ROUTES.restaurants,
    element: RestaurantsPage,
  },
  {
    path: ROUTES.addRestaurant,
    element: AddOrUpdateRestaurant,
  },
  {
    path: ROUTES.editRestaurant,
    element: AddOrUpdateRestaurant,
  },

  {
    path: ROUTES.orders,
    element: OrdersPage,
  },
  {
    path: ROUTES.order,
    element: OrderPage,
  },
  {
    path: ROUTES.menu,
    element: MenuPage,
  },
  {
    path: ROUTES.addDish,
    element: AddOrUpdateDish,
  },
  {
    path: ROUTES.editDish,
    element: AddOrUpdateDish,
  },
  {
    path: ROUTES.users,
    element: UsersPage,
  },
  {
    path: ROUTES.adminOffers,
    element: AdminOffersPage,
  },
  {
    path: ROUTES.addAdminOffers,
    element: AddOrUpdateAdminOffersPage,
  },
  {
    path: ROUTES.editAdminOffers,
    element: AddOrUpdateAdminOffersPage,
  },
  {
    path: ROUTES.adminOffer,
    element: AdminOfferEditDishesPage,
  },
  {
    path: ROUTES.applications,
    element: ApplicationsPage,
    isSuperAdminRoute: true,
  },
  {
    path: ROUTES.application,
    element: Application,
  },
  {
    path: ROUTES.brands,
    element: BrandsPage,
    isSuperAdminRoute: true,
  },
  {
    path: ROUTES.brand,
    element: Brand,
    isSuperAdminRoute: true,
  },
  {
    path: ROUTES.offers,
    element: OffersSearchedNowPage,
    isSuperAdminRoute: true,
  },
  {
    path: ROUTES.offersInfo,
    element: OffersSearchedNowInfoPage,
    isSuperAdminRoute: true,
  },
  {
    path: ROUTES.settings,
    element: SettingsPage,
    isSuperAdminRoute: true,
  },
  {
    path: ROUTES.translations,
    element: TranslationsPage,
    isSuperAdminRoute: true,
  },
  {
    path: ROUTES.addBrands,
    element: AddOrUpdateBrand,
    isSuperAdminRoute: true,
  },
  {
    path: ROUTES.editBrands,
    element: AddOrUpdateBrand,
    isSuperAdminRoute: true,
  },
  {
    path: ROUTES.addUser,
    element: AddOrUpdateUser,
  },
  {
    path: ROUTES.editUser,
    element: AddOrUpdateUser,
  },
  {
    path: ROUTES.restaurant,
    element: Restaurant,
  },
  {
    path: ROUTES.privacyPolicy,
    element: PrivacyPolicy,
    isAuthRoute: true,
  },
  {
    path: ROUTES.agreement,
    element: AgreementPage,
    isAuthRoute: true,
  },
];

export const RouterWrapper = () => {
  const { isAuth, user } = useAuthContext();
  const isSuperAdmin = !user?.brand;

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute
                {...route}
                isAuth={isAuth}
                isSuperAdmin={isSuperAdmin}
              />
            }
          />
        ))}
        <Route key={'NotFound'} path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

type ProtectedRouteType = {
  element: any;
  path: string;
  isAuth: boolean;
  isSuperAdmin?: boolean;
};

const ProtectedRoute = ({
  element: Element,
  path,
  isAuth = true,
  isSuperAdmin,
}: ProtectedRouteType) => {
  // исключение для страниц не требующих авторизации
  if (authRoutCheck(path)) {
    if (isAuth) {
      return (
        <Navigate
          to={isSuperAdmin ? ROUTES.applications : ROUTES.restaurants}
        />
      ); //TODO
    }
    return <Element />;
  }

  if (isAuth) {
    if (!isSuperAdmin) {
      if (!adminRouteCkeck(path) || path === ROUTES.home) {
        return <Navigate to={ROUTES.restaurants} />;
      }
      return <Element />;
    } else {
      if (!superAdminRouteCkeck(path) || path === ROUTES.home) {
        return <Navigate to={ROUTES.applications} />; //TODO
      }

      return <Element />;
    }
  }

  return <Navigate to={ROUTES.login} />;
};

const authRoutCheck = (path: string) => {
  const authRout = routes
    .filter((route) => route.isAuthRoute)
    .map((i) => i.path);
  return authRout.includes(path);
};

const adminRouteCkeck = (path: string) => {
  const adminRout = routes
    .filter((route) => !route.isAuthRoute && !route.isSuperAdminRoute)
    .map((i) => i.path);
  return adminRout.includes(path);
};

const superAdminRouteCkeck = (path: string) => {
  const superAdminRout = routes
    .filter((route) => !route.isAuthRoute)
    .map((i) => i.path);

  return superAdminRout.includes(path);
};
