import { EmptyList, RestaurantItem } from '..';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { Restaurant } from '../../graphql/generated/graphql';
import { Wrapper } from './styles';

interface IProps {
  data?: Restaurant[];
  loading?: boolean;
  isHasAddButton?: boolean;
  handleAddRestourant?: () => void;
}

export const RestaurantList = ({
  data,
  isHasAddButton = false,
  handleAddRestourant,
}: IProps) => {
  const { t } = useTranslatesContext();

  if (data && data.length === 0) {
    return (
      <EmptyList
        sx={{ marginTop: '136px' }}
        title={t('web_restaraunts_empty_title')}
        img={t('web_restaraunts_emtpy_img')}
        button={
          isHasAddButton
            ? {
                title: t('web_restaraunts_add_btn'),
                action: handleAddRestourant,
              }
            : null
        }
      />
    );
  }

  return (
    <Wrapper>
      {data?.map((data) => {
        return (
          <RestaurantItem
            key={data.id}
            id={data.id}
            title={data.name}
            ratingIn={''}
            ratingOut={''}
            close={data.isClosed}
            address={data.address}
            description={data.description}
            brandId={data.brandId}
          />
        );
      })}
    </Wrapper>
  );
};
