import { OptionsCaseLayout, TextFieldFormik } from '../../components';
import { FieldArray, Form, Formik } from 'formik';
import {
  ButtonSaveChangesCSS,
  FieldArrayRow,
  FieldArrayRowInner,
} from './styles';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { CloseModalIcon } from '../../components/Icons';
import { useTranslatesContext } from '../../context/TranslatesContext';
import {
  IFormikSettingsCitiesFormProps,
  ISettingsCitiesFormProps,
} from './types';
import { editCitiesSchema } from '../../validation';
import { useBulkUpdateCityMutation } from '../../graphql/generated/graphql';
import { useAlertContext } from '../../context';
import { CITIES } from '../../graphql/queries/cities.gqll';

export const SettingsCitiesForm = ({
  citiesOption,
}: ISettingsCitiesFormProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [updateCity] = useBulkUpdateCityMutation({
    onCompleted: () =>
      onOpenAlert({ title: t('web_setting_list_cities_update') }),
    onError: () =>
      onOpenAlert({
        title: t('web_setting_occurred_error_update'),
        error: true,
      }),
    refetchQueries: [CITIES],
  });

  const initialValues = {
    cities:
      citiesOption?.length < 1
        ? [{ label: '', latitude: 0, longitude: 0 }]
        : citiesOption,
  };

  const handleSubmit = (values: IFormikSettingsCitiesFormProps) => {
    const deleteItems: any = [];

    citiesOption.forEach((item) => {
      if (
        !values.cities
          .map((i) => i.id)
          .filter((i) => i)
          .includes(item.id)
      ) {
        deleteItems.push({
          title: item.label,
          id: Number(item.id),
          latitude: Number(item.latitude),
          longitude: Number(item.longitude),
          isDelete: true,
        });
      }
    });

    const updateItems = values.cities.map((item) => {
      const idParams = item.id
        ? {
            id: Number(item.id),
          }
        : {};

      return {
        title: item.label,
        latitude: Number(item.latitude),
        longitude: Number(item.longitude),
        ...idParams,
      };
    });

    updateCity({
      variables: {
        citiesData: {
          list: [...updateItems, ...deleteItems],
        },
      },
    });
  };

  return (
    <OptionsCaseLayout title={t('web_setting_case_label_city')}>
      <Box mb={'8px'} sx={{ display: 'flex' }}>
        <Typography
          variant={'body1'}
          mr={'16px'}
          width={'100%'}
          maxWidth={'385px'}
        >
          {t('web_setting_label_city_name')}
        </Typography>
        <Typography variant={'body1'} width={'100%'} maxWidth={'165px'}>
          {t('web_setting_label_city_longitude')}
        </Typography>
        <Typography variant={'body1'}>
          {t('web_setting_label_city_latitude')}
        </Typography>
        <Box sx={{ width: '100%', maxWidth: '100px' }} />
      </Box>
      <Formik<IFormikSettingsCitiesFormProps>
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={editCitiesSchema}
        onSubmit={handleSubmit}
      >
        {({ values, dirty }) => {
          return (
            <Form>
              <FieldArray name={'cities'}>
                {({ remove, push }) => {
                  return (
                    <FieldArrayRow>
                      {values.cities.map((city, index) => {
                        return (
                          <FieldArrayRowInner key={index}>
                            <TextFieldFormik
                              name={`cities.${index}.label`}
                              fullWidth
                              width={'384px'}
                              size={'large'}
                            />
                            <TextFieldFormik
                              name={`cities.${index}.longitude`}
                              fullWidth
                              width={'150px'}
                              size={'large'}
                              type={'number'}
                            />
                            <TextFieldFormik
                              name={`cities.${index}.latitude`}
                              fullWidth
                              width={'150px'}
                              size={'large'}
                              type={'number'}
                            />
                            <IconButton
                              sx={{ mb: '6px' }}
                              size={'large'}
                              onClick={() => remove(index)}
                            >
                              <CloseModalIcon />
                            </IconButton>
                          </FieldArrayRowInner>
                        );
                      })}
                      <Stack direction={'row'} spacing={1}>
                        <Button
                          onClick={() =>
                            push({
                              label: '',
                              latitude: '',
                              longitude: '',
                            })
                          }
                          size={'large'}
                          sx={{ width: '100%', maxWidth: '160px' }}
                        >
                          {t('web_setting_action_city_add')}
                        </Button>
                        <ButtonSaveChangesCSS
                          type='submit'
                          size={'large'}
                          disabled={!dirty}
                        >
                          {t('web_action_save')}
                        </ButtonSaveChangesCSS>
                      </Stack>
                    </FieldArrayRow>
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </OptionsCaseLayout>
  );
};
