import React, { useState } from 'react';
import {
  HeaderForBrand,
  MainLayout,
  PageTitleBlock,
  RequestHandler,
  RestaurantList,
  UserList,
} from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';
import { useTranslatesContext } from '../../context/TranslatesContext';
import {
  Manager,
  Restaurant,
  useBrandQuery,
  useManagersQuery,
  useRestaurantsQuery,
} from '../../graphql/generated/graphql';

export const Brand = () => {
  const { t } = useTranslatesContext();
  const { id } = useParams();
  const [tabNum, setTabNum] = useState<string>(
    localStorage.getItem('tabNum') || '1',
  );
  const isRestaurantPage = tabNum === '1';
  const navigate = useNavigate();

  const { data, loading, error } = useRestaurantsQuery({
    variables: {
      getRestaurantsInput: {
        brandId: Number(id),
        limit: 1000,
        offset: 0,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { data: dataBrands } = useBrandQuery({
    variables: {
      brandId: Number(id),
    },
  });

  const handleClickTabs = (value: string) => {
    setTabNum(value);
    localStorage.setItem('tabNum', value);
  };

  const handleCreate = () => {
    if (isRestaurantPage) {
      navigate(`${ROUTES.addRestaurant}/?brandId=${id}`);
    } else {
      navigate(`${ROUTES.addUser}/?brandId=${id}`);
    }
  };

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useManagersQuery({
    variables: {
      filter: {
        brandId: Number(id),
      },
    },
    fetchPolicy: 'network-only',
  });

  return (
    <MainLayout>
      <HeaderForBrand
        brandName={dataBrands?.brand.title}
        urlImage={dataBrands?.brand.image.url}
      />
      <PageTitleBlock
        buttonText={
          isRestaurantPage
            ? t('web_brand_add_rest_btn')
            : t('web_brand_add_user_btn')
        }
        sx={{ marginBottom: '32px', marginTop: '80px' }}
        enableTabs
        tabNum={tabNum}
        onClick={handleCreate}
        onClickTabs={handleClickTabs}
      />
      <RequestHandler
        loading={loading || usersLoading}
        error={error || usersError}
        height='50vh'
      >
        {isRestaurantPage ? (
          <RestaurantList data={data?.restaurants.rows as Restaurant[]} />
        ) : (
          <UserList data={usersData?.managers as Manager[]} />
        )}
      </RequestHandler>
    </MainLayout>
  );
};
