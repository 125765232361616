import React, { useEffect } from 'react';
import { ContentCSS } from './styles';
import {
  Offer_Availability_Enum,
  Order_Offer_By_Enum,
  Restaurant,
  Schedule_Enum,
  Sort_Direction_Enum,
  useOffersByBrandLazyQuery,
} from '../../graphql/generated/graphql';
import { AdminOffersItem } from '../AdminOffersItem/AdminOffersItem';
import { RequestHandler } from '../UI/RequestHandler/RequestHandler';
import { EmptyList } from '../EmptyList/EmptyList';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { transformWorkTimeString } from '../../utils';

interface IAdminOffersListsMainProps {
  tabNum: string;
  searchText: string;
}

export const AdminOffersListsMain = ({
  tabNum,
  searchText,
}: IAdminOffersListsMainProps) => {
  const { t } = useTranslatesContext();

  const [getOffers, { data, loading, error }] = useOffersByBrandLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const getStatus = (tabNum: string) => {
    switch (tabNum) {
      case '1':
        return Offer_Availability_Enum.Active;
      case '2':
        return Offer_Availability_Enum.Stoped;
      case '3':
        return Offer_Availability_Enum.Closed;
      default:
        return Offer_Availability_Enum.Active;
    }
  };

  useEffect(() => {
    getOffers({
      variables: {
        options: {
          limit: 1000,
          offset: 0,
          search: searchText,
          status: getStatus(tabNum) as Offer_Availability_Enum,
          sort: {
            direction: Sort_Direction_Enum.Asc,
            by: Order_Offer_By_Enum.CreatedAt,
          },
        },
      },
    });
  }, [searchText, tabNum]);

  return (
    <RequestHandler loading={loading} error={error} height={'50vh'}>
      {data && data?.offersByBrand.rows?.length === 0 ? (
        <EmptyList
          sx={{ marginTop: '136px' }}
          title={t('web_offers_emtpy_title')}
          img={t('web_offers_emtpy_img')}
        />
      ) : (
        <ContentCSS>
          {data?.offersByBrand.rows.map((offer) => {
            const workDayValue = `${t(
              'web_offer_schedule_from',
            )} ${transformWorkTimeString(offer.workDays[0].from)} ${t(
              'web_offer_schedule_to',
            )} ${transformWorkTimeString(offer.workDays[0].to)}`;

            const scheduleValue =
              offer.scheduleType === Schedule_Enum.Flexible ? '' : workDayValue;
            return (
              <AdminOffersItem
                key={offer.id}
                scheduleValue={scheduleValue}
                id={offer.id}
                name={offer.name}
                budgetPerPerson={offer.budgetPerPerson}
                restaurants={offer.restaurants as Restaurant[]}
                orderStatus={getStatus(tabNum)}
              />
            );
          })}
        </ContentCSS>
      )}
    </RequestHandler>
  );
};
